import React, { FC } from 'react';
import { PageHeader, UserStateComponent } from '../components';

export const Changelog: FC = () => {
  return (
    <UserStateComponent>
      <PageHeader>Changelog</PageHeader>
      <h3>Oct 8, 2024</h3>
      <ul>
        <li>Cashiers can check users in when there is no badge design available.</li>
        <li>UI fixes on user notes page.</li>
      </ul>
      <h3>Sept 15, 2024</h3>
      <ul>
        <li>
          Allow user search in cashier mode, by entering the user id and then pressing 'Enter'.
        </li>
      </ul>
      <h3>Aug 13, 2024</h3>
      <ul>
        <li>
          Added an option to have product grants expire after a specific time. This feature is
          designed for single-day passes.
        </li>
      </ul>
      <h3>Aug 9, 2024</h3>
      <ul>
        <li>Dealers get a full sub-page for all the options.</li>
        <li>
          Fix issue when user changes registration to a different product with different options.
        </li>
      </ul>
      <h3>Aug 7, 2024</h3>
      <ul>
        <li>Allow admins to force user registration with a specific product.</li>
        <li>Add an official API to remove product grants.</li>
      </ul>
      <h3>Jun 30, 2024</h3>
      <ul>
        <li>Removed konami code from cashier page.</li>
        <li>Add custom email templates for conventions.</li>
        <li>
          Fix issue with dealers coming back from previous year having values incorrectly filled.
        </li>
      </ul>
      <h3>Jun 25, 2024</h3>
      <ul>
        <li>Filters for the voucher list page.</li>
        <li>Show products as soldout when a free addon is sold out.</li>
      </ul>
      <h3>Jun 19, 2024</h3>
      <ul>
        <li>Added a stats page for options.</li>
        <li>Ability to edit option values after they have been created.</li>
        <li>Auto-focus 2FA input box on login.</li>
      </ul>
      <h3>Jun 16, 2024</h3>
      <ul>
        <li>Fix logout from the non-primary convention.</li>
        <li>Options can now be included on dealer table applications.</li>
      </ul>
      <h3>Jun 15, 2024</h3>
      <ul>
        <li>
          Disable changing the staff comp voucher value. Conventions can continue to use the current
          feature, but it will set to 0 starting the next convention year. Conventions should use
          product discounts instead.
        </li>
      </ul>
      <h3>Jun 11, 2024</h3>
      <ul>
        <li>Display if a dealer has a restriction.</li>
      </ul>
      <h3>Jun 7, 2024</h3>
      <ul>
        <li>Non-free addon options should not be included on the parent product.</li>
      </ul>
      <h3>Jun 6, 2024</h3>
      <ul>
        <li>Fix badge designer input when trying to put numbers.</li>
        <li>Use deep URLs when looking at product categories on the store page.</li>
        <li>Fix registration not loading values when loading non-free options during a raffle.</li>
        <li>Hide deleted products from the product management page by default.</li>
        <li>
          The buy button on a product will become disabled after an out of stock message is
          received.
        </li>
      </ul>
      <h3>Jun 3, 2024</h3>
      <ul>
        <li>Product addon options will now be displayed on the registration.</li>
        <li>Allow deletion of emergency contact entries.</li>
      </ul>
      <h3>May 31, 2024</h3>
      <ul>
        <li>
          Convnetion contact settings now uses the same domain as the convention email
          configuration.
        </li>
        <li>
          Store products can now be restricted by the user having a registration or another product
          grant.
        </li>
      </ul>
      <h3>May 26, 2024</h3>
      <ul>
        <li>Show errors from Stripe on the user logs when a payment fails.</li>
        <li>
          Allow conventions to have a different e-mail domain per convnetion. For organizations that
          support more than 1 convention.
        </li>
      </ul>
      <h3>May 19, 2024</h3>
      <ul>
        <li>
          Re-worked the notes and ban systems system. User bans is now a special type of user notes.
        </li>
        <li>
          A new permission 'user:note:read:hr', that allows the user to read bans and restrictions.
        </li>
        <li>
          Restrictions are no longer shown to the cashier. Instead a "cashier" note type has been
          added.
        </li>
      </ul>
      <h3>May 14, 2024</h3>
      <ul>
        <li>Fix raffle user search list.</li>
      </ul>
      <h3>Apr 27, 2024</h3>
      <ul>
        <li>Show the registration paid label on the dealer's list.</li>
      </ul>
      <h3>Apr 20, 2024</h3>
      <ul>
        <li>Add an API for querying order status.</li>
        <li>Add a limit for the number of dealer assistants.</li>
        <li>Show dealer assistant status on the user profile page.</li>
        <li>Option to disable volunteer emails from being sent out.</li>
        <li>Fix volunteer staff request.</li>
        <li>Volunteer list now shows if the user has restrictions.</li>
        <li>"My Departments" only shows volunteers for the user's own department.</li>
      </ul>
      <h3>Mar 22, 2024</h3>
      <ul>
        <li>Add "Returning Attendee" profile badge.</li>
      </ul>
      <h3>Mar 10, 2024</h3>
      <ul>
        <li>Add counter of pending refunds to the housekeeping dashboard.</li>
        <li>Better error message when attendees select more than 5 departments.</li>
        <li>Add official API registration search by product id.</li>
      </ul>
      <h3>Mar 3, 2024</h3>
      <ul>
        <li>New export for full order details.</li>
      </ul>
      <h3>Feb 26, 2024</h3>
      <ul>
        <li>Added logs about emails being sent.</li>
        <li>Cleanup dynamic form registration code.</li>
      </ul>
      <h3>Feb 24, 2024</h3>
      <ul>
        <li>Add event hooks for dealer.</li>
        <li>Fix voucher expiration logic.</li>
      </ul>
      <h3>Feb 20, 2024</h3>
      <ul>
        <li>Cashiers can update the user's profile when the scan does not match.</li>
        <li>Fix manual account creation page. Only username and email is required.</li>
      </ul>
      <h3>Feb 14, 2024</h3>
      <ul>
        <li>Add stripe fee information to the payment stats page.</li>
        <li>
          Fixed bug when product discount was displayed to users with convention specific role.
        </li>
        <li>Fixed bug when scanning driver's licenses from some states.</li>
      </ul>
      <h3>Feb 9, 2024</h3>
      <ul>
        <li>Option to choose what labels do display on special labels for the badge designer.</li>
      </ul>
      <h3>Feb 4, 2024</h3>
      <ul>
        <li>Added option to disable refunds.</li>
      </ul>
      <h3>Feb 2, 2024</h3>
      <ul>
        <li>
          Added swagger documentation page at <a href="/api/docs">/api/docs</a>.
        </li>
      </ul>
      <h3>Jan 24, 2024</h3>
      <ul>
        <li>Show convention year on role users page.</li>
        <li>Disable refunds on free products.</li>
      </ul>
      <h3>Jan 10, 2024</h3>
      <ul>
        <li>Addons are tracked separetly when delivering items to users.</li>
        <li>Added option to print datamatrix barcodes on the badge.</li>
      </ul>
      <h3>Jan 9, 2024</h3>
      <ul>
        <li>
          Added receipt printer connection to cashier interface. (
          <a href="https://github.com/Cellivar">Cellivar</a>)
        </li>
      </ul>
    </UserStateComponent>
  );
};
