import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { noteCategories, NoteCategory, UserNoteCreate } from '../../../../../shared/user/notes';
import { PageHeader, PermissionBoundary, UserStateComponent } from '../../../../components';
import { AddNoteForm } from '../../../../modules/notes/addNoteForm';
import { UserNoteItem } from '../../../../modules/notes/noteItem';
import { capitalize, Fetcher, useFetcher, useForm, useInputState } from '../../../../utils';
import { useDebounceValue } from '../../../../utils/debounce';
import { captureError } from '../../../../utils/errorHandling';

export const UserNotescomponent: FC = () => {
  const [search, setSearch] = useInputState('');
  const [category, setCategory] = useInputState<NoteCategory | 'all'>('all');
  const [createModal, setCreateModal] = useState(false);

  const searchDebounce = useDebounceValue(search, 500);

  const fetcher = useFetcher(async () => {
    return await api.getNotes({
      category: category === 'all' ? undefined : category,
      search: searchDebounce === '' ? undefined : searchDebounce,
    });
  }, [category, searchDebounce]);

  if (!fetcher.data || fetcher.error) {
    return <Fetcher result={fetcher} />;
  }

  return (
    <UserStateComponent>
      <PageHeader>User Notes</PageHeader>
      {createModal && (
        <CreateNoteModal
          close={() => {
            setCreateModal(false);
            fetcher.refresh();
          }}
        />
      )}
      <Row>
        <Col>
          <FormGroup>
            <Input
              autoFocus
              id="searchNotes"
              onChange={setSearch}
              placeholder="Search notes..."
              type="search"
              value={search}
            />
          </FormGroup>
        </Col>
        <Col md={6} xs={12}>
          <Input
            className="margin-bottom-10"
            id="category"
            name="category"
            onChange={setCategory}
            style={{ width: 'auto', display: 'inline' }}
            type="select"
            value={category}
          >
            <option value="all">(All)</option>
            {noteCategories.map((cat) => (
              <option key={cat} value={cat}>
                {capitalize(cat)}
              </option>
            ))}
          </Input>
        </Col>
        <PermissionBoundary inline requiredPermissions={['user:note:create']}>
          <Col md="auto">
            <Button color="primary" id="addNoteModal" onClick={() => setCreateModal(true)}>
              Create Note
            </Button>
          </Col>
        </PermissionBoundary>
      </Row>

      {fetcher.data?.map((note) => {
        return <UserNoteItem key={note.id} note={note} refresh={fetcher.refresh} />;
      })}
    </UserStateComponent>
  );
};

interface CreateNoteModalProps {
  close(): void;
}

const CreateNoteModal: React.FC<CreateNoteModalProps> = ({ close }) => {
  const [note, setNote] = useState<UserNoteCreate>({
    category: 'misc',
    text: '',
  });

  const form = useForm(async () => {
    if (!note.userId && note.category !== 'ban') {
      toast.error('User must be selected');
      return;
    }

    try {
      await api.createUserNote(note);
      toast.success('Note has been added');
      close();
    } catch (error) {
      captureError(error as Error);
    }
  }, [note]);

  return (
    <Modal className="modal-large" id="createNoteModal" isOpen toggle={close}>
      <form onSubmit={form.onSubmit}>
        <ModalHeader>Create New User Note</ModalHeader>
        <ModalBody>
          <AddNoteForm note={note} setNote={setNote} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={form.saving} id="complete" type="submit">
            Create
          </Button>{' '}
          <Button color="secondary" id="cancel" onClick={close} outline>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
