import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { AccessDenied, PermissionBoundary } from '../../../components';
import { UserSelector } from '../../../components/UserSelector';
import { useUser } from '../../../utils';
import { captureError } from '../../../utils/errorHandling';

export const AuthorizeDevice: React.FC = () => {
  const user = useUser()!;
  const [userId, setUserId] = useState(user?.id ?? 0);
  const [jwtInfo, setJwtInfo] = useState<{ jwt: string; expires: Date } | null>(null);

  const resetAuth = useCallback(() => {
    setJwtInfo(null);
    setUserId(user.id);
  }, []);

  const requestAuth = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const { jwt, expires } = await api.authorizeDevice(
        'quikticket',
        userId === user.id ? undefined : userId,
      );

      setJwtInfo({
        jwt,
        expires,
      });
    } catch (error) {
      captureError(error as Error);
    }
  }, []);

  return (
    <PermissionBoundary
      inline
      inlineError={<AccessDenied message="You do not have permission to authorize a new device." />}
      matchAny
      requiredPermissions={['admin:ticket:scan', 'admin:ticket:read', 'system:devices:manage']}
    >
      {jwtInfo ? (
        <div>
          <h6>Authorization Code</h6>
          <p>
            <small>
              Scan the QR code below with the device you wish to authorize. The code will expire on{' '}
              {jwtInfo.expires.toLocaleString()}.
            </small>
          </p>
          <div className="text-center">
            <QRCodeSVG value={jwtInfo.jwt} />
          </div>
          <hr />
          <Button block color="secondary" onClick={resetAuth} outline>
            Request New Code
          </Button>
        </div>
      ) : (
        <div>
          <PermissionBoundary inline requiredPermissions={['system:devices:manage']}>
            <h6>Admin Authorize for User</h6>
            <p>
              <small>
                To authorize a device for another user, select the user below. If you leave this
                blank, the device will be authorized for your account.
              </small>
            </p>
            <UserSelector
              id="customRecipients"
              maxItems={1}
              selectionIdsChanged={(ids) => setUserId(ids[0] ?? 0)}
            />
            <hr />
          </PermissionBoundary>
          <Button block color="primary" onClick={requestAuth}>
            Request Code
          </Button>
        </div>
      )}
    </PermissionBoundary>
  );
};
