import * as Sentry from '@sentry/browser';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Footer, Loading, UserRoute } from './components';
import { ExternalOauthLoginComponent } from './components/ExternalOauthLoginComponent';
import { NetworkStatus } from './components/NetworkStatus';
import {
  CashierComponent,
  ConfirmComponent,
  CoreContainer,
  LoginComponent,
  RegisterComponent,
  ResetComponent,
} from './containers';
import { ChangeEmailComponent } from './containers/account/emailconfirm';
import { DealerContainerComponent } from './modules/dealer/dealerContainer';
import { ActionType, store, Theme } from './services';
import { classNames, noop, useConfig, useGlobalState, usePreferences, useUser } from './utils';
import { captureError } from './utils/errorHandling';
import { makeLazyComponent } from './utils/lazy';

const KioskComponent = makeLazyComponent(
  async () =>
    (await import(/* webpackChunkName: "kiosk" */ './containers/kiosk')).KioskBoundaryComponent,
);

const HousekeepingComponent = makeLazyComponent(
  async () =>
    (await import(/* webpackChunkName: "housekeeping" */ './containers/housekeeping'))
      .HousekeepingComponent,
);

const ExpressEditorComponent = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "expresseditor" */ './containers/housekeeping/expressEditor'
      )
    ).ExpressEditorComponent,
);

const VolunteerComponent = makeLazyComponent(
  async () =>
    (
      await import(
        /* webpackChunkName: "volunteerManagment" */ './containers/housekeeping/volunteers/volunteerContainer'
      )
    ).VolunteerContainerComponent,
);

export const ConCatApp: FC = () => {
  const [userPollComplete, setUserPollComplete] = useState(false);
  const location = useLocation();
  const user = useUser();
  const bootstrapCSSElement = useMemo(
    () => document.getElementById('bootstrapv4') as HTMLLinkElement,
    [],
  );

  useEffect(() => {
    api
      .getConfig()
      .then((cfg) => {
        store.dispatch({
          config: cfg,
          type: ActionType.ConfigReceived,
        });

        const activeConvention = cfg.conventions.find(
          (c) => c.domain === window.location.host.toLowerCase(),
        );

        document.title = `${activeConvention?.longName ?? cfg.organization.name} - ConCat`;
      })
      .catch(captureError);
  }, []);

  useEffect(() => {
    api
      .getActiveUser()
      .then((usr) => {
        store.dispatch({
          type: ActionType.LoginReturning,
          user: usr,
        });
      })
      .catch(noop)
      .then(() => {
        setUserPollComplete(true);

        store.dispatch({
          type: ActionType.LoadingComplete,
        });
      })
      .catch(noop);
  }, []);

  useEffect(() => {
    if (bootstrapCSSElement.disabled && location.pathname !== '/kiosk') {
      // Unhide CSS if it's currently hidden, and we're not on the kiosk anymore
      bootstrapCSSElement.disabled = false;
    }
  }, [bootstrapCSSElement.disabled, location]);

  const preferences = usePreferences()!;
  const config = useConfig();
  const loaded = useGlobalState((state) => state.loaded);
  const showSidenav = useGlobalState((state) => state.showSidenav);
  const bodyOnly = useGlobalState((state) => state.bodyOnly);

  useEffect(() => {
    if (SENTRY_DSN && config) {
      const scope = Sentry.getCurrentScope();
      scope.setUser({ id: user?.id.toString() });
      scope.setTag('organizationId', config.organization.id);
    }
  }, [user?.id, config?.organization.id]);

  useEffect(() => {
    // TODO: How to do this properly with modals?
    document.body.classList.toggle('dark-mode', preferences.theme === Theme.Dark);
  }, [preferences.theme]);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasLoaded = config && userPollComplete;

  return (
    <div
      className={classNames({
        'sidenav-active': loaded && showSidenav && !!user && !bodyOnly,
      })}
      id="wrapper"
    >
      <NetworkStatus />
      {hasLoaded && (
        <Switch>
          <Route component={KioskComponent} exact path="/kiosk" />
          <Route component={LoginComponent} exact path="/login" />
          <Route component={ResetComponent} exact path="/login/reset" />
          <Route component={RegisterComponent} exact path="/register" />
          <Route component={ExternalOauthLoginComponent} exact path="/neosvrauth" />
          <Route component={ConfirmComponent} path="/register/confirm/:code" />
          <Route component={ChangeEmailComponent} path="/register/email/:code" />
          <UserRoute
            component={CashierComponent}
            path="/housekeeping/cashier"
            requirePermissions={['registration:cashier']}
          />
          <UserRoute component={VolunteerComponent} path="/housekeeping/volunteers" />
          <UserRoute
            component={DealerContainerComponent}
            path="/housekeeping/dealers"
            requirePermissions={['vendor:read']}
          />
          <UserRoute
            component={ExpressEditorComponent}
            path="/housekeeping/editor"
            requirePermissions={['system:login']}
          />
          <UserRoute
            component={HousekeepingComponent}
            path="/housekeeping"
            requirePermissions={['system:login']}
          />
          <Route component={CoreContainer} />
        </Switch>
      )}
      {!hasLoaded && <Loading inline />}
      {!bodyOnly && <Footer />}
    </div>
  );
};
