import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Device } from '../../shared/device';
import { MaterialIcon } from './MaterialIcon';

import './Device.scss';

interface DeviceProps {
  readonly device: Device;
}

export const DeviceListItem: FC<DeviceProps> = ({ device }) => {
  return (
    <Card className="device-item">
      <CardBody className="device-item-body">
        <div className="device-icon">
          <MaterialIcon
            medium
            name={device.make.toLowerCase().includes('apple') ? 'phone_iphone' : 'phone_android'}
          />
        </div>
        <div className="device-info">
          <div className="device-identifiers main-col">
            <div className="device-id-main">{device.id}</div>
            <div className="device-id">Device ID: {device.identifier}</div>
          </div>
          <div className="device-metadata">
            <div>Running QuikTicket {device.appVersion}</div>
            <div className="device-makemodel">
              {device.make} {device.model} ({device.osVersion})
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
