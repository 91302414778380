import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PageHeader, UserStateComponent } from '../../../components';
import { DeviceListItem } from '../../../components/Device';
import { useUser } from '../../../utils';
import { LoadingWrapper } from '../../../utils/LoadingWrapper';
import { AuthorizeDevice } from './authorize';

export const DevicesPage: React.FC = () => {
  const user = useUser();

  return (
    <>
      <PageHeader>Devices</PageHeader>
      <UserStateComponent>
        <LoadingWrapper
          dataFetcher={async () => (user ? await api.getUserDeviceList(user?.id) : [])}
          inline
        >
          {(devices) => (
            <Row>
              <Col lg={8} xs={12}>
                {devices.map((device) => (
                  <DeviceListItem device={device} key={device.id} />
                ))}
              </Col>
              <Col lg={4} xs={12}>
                <Card>
                  <CardBody>
                    <h4>Authorize New Device</h4>
                    <p>
                      <small>
                        To authorize a new device, click the "Request Code" button below and scan
                        the QR code with the device you wish to authorize.
                      </small>
                    </p>
                    <p>
                      <small>
                        Devices are authorized for 6 hours before requiring reauthorization with a
                        new code.
                      </small>
                    </p>
                    <hr />
                    <AuthorizeDevice />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </LoadingWrapper>
      </UserStateComponent>
    </>
  );
};
