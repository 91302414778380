import React, { FC, ReactNode } from 'react';
import { PermissionName } from '../../shared/permissions';
import { checkPermission, useUser } from '../utils';
import { AccessDenied } from './AccessDenied';

interface PermissionBoundaryProps {
  readonly inline?: boolean;
  readonly requiredPermissions?: PermissionName[];
  readonly matchAny?: boolean;
  readonly inlineError?: ReactNode;
}

const emptyArray: PermissionName[] = [];

export const PermissionBoundary: FC<PermissionBoundaryProps> = ({
  children,
  inline = false,
  inlineError = null,
  requiredPermissions = emptyArray,
  matchAny = false,
}) => {
  const user = useUser();

  if (
    (!user && requiredPermissions.length > 0) ||
    !checkPermission(user!, requiredPermissions, matchAny)
  ) {
    return inline ? <>{inlineError}</> : <AccessDenied />;
  }

  return <>{children}</>;
};
