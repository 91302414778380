import './polyfill';
import { APIClient as BaseClient } from '@conventioncatcorp/common-fe/dist/APIClient';
import { init } from '@conventioncatcorp/common-fe/dist/components/json-form/JSONForm';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { render } from 'react-dom';
import 'react-phone-input-2/lib/bootstrap.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { APIClient } from './APIClient';
import { ConCatApp } from './ConCatApp';
import { store } from './services';
import './style/index.scss';
import { captureError } from './utils/errorHandling';

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.extraErrorDataIntegration()],
  });
}

const httpBase = new BaseClient();
window.api = new APIClient(new BaseClient());

init(httpBase, captureError);

render(
  <Provider store={store}>
    <BrowserRouter>
      <ConCatApp />
    </BrowserRouter>
    <ToastContainer autoClose={7000} draggable newestOnTop pauseOnFocusLoss />
  </Provider>,
  document.getElementById('app'),
);

declare global {
  let api: APIClient;
  interface Window {
    api: APIClient;
    reactHistory: (path: string) => void;
  }
  const VERSION: string;
  const SENTRY_DSN: string;
}
